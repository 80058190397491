/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        
        jQuery('.home-slider').slick({
          dots: false,
          infinite: true,
          speed: 500,
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          cssEase: 'linear'

        });

        jQuery('.gallery-slide').slick({

            dots: false,
            infinite: true,
            speed: 500,
            prevArrow: '.button-prev1',
            nextArrow: '.button-next1',
            autoplay:true

        });

        $('#exampleModal').on('shown.bs.modal', function () {

           $('#video')[0].play(); });
           
           $('#exampleModal').on('hidden.bs.modal', function () {
             
            $('#video')[0].pause(); 
          
          })

          $('#exampleModal1').on('shown.bs.modal', function () {

            $('#video1')[0].play(); });
            
            $('#exampleModal1').on('hidden.bs.modal', function () {
              
             $('#video1')[0].pause(); 
           
           })

          $('#singlevideo').on('shown.bs.modal', function () {

            $('#video')[0].play(); });
            
            $('#singlevideo').on('hidden.bs.modal', function () {
              
             $('#video')[0].pause(); 
           
           })


        $(document).ready(function(){

          if(window.matchMedia("(max-width: 767px)").matches){
    
            jQuery('.menu-item a').click(function() {
            jQuery('.navbar-collapse').collapse('hide');
            });
    
          }
    
        });


        jQuery('.videoc-right-slide').slick( {

          dots: false,
          infinite: true,
          speed: 500,
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          cssEase: 'linear'
   
        });

        jQuery('.onec-side-slider').slick( {
    
          dots: false,
          infinite: true,
          speed: 500,
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          cssEase: 'linear'
            
          });


    jQuery(document).ready(function(){
      jQuery(".contentf1").slice(0, 5).show();
      jQuery("#loadMoref1").on("click", function(e){
      e.preventDefault();
      jQuery(".contentf1:hidden").slice(0, 4).slideDown();
      if(jQuery(".contentf1:hidden").length == 0) {
          jQuery("#loadMoref1").text("No further Content").addClass("noContent");
      }
    });
    
  })


  jQuery(document).ready(function(){
    jQuery(".content3").slice(0, 5).show();
    jQuery("#loadMore3").on("click", function(e){
    e.preventDefault();
    jQuery(".content3:hidden").slice(0, 4).slideDown();
    if(jQuery(".content3:hidden").length == 0) {
        jQuery("#loadMore3").text("No further Content").addClass("noContent");
    }
  });
  
})

      jQuery(".main-slide").slick({

          dots: true,
          speed: 300,
          slidesToShow: 1,
          fade: true,
          slidesToScroll: 1,
          arrows: true,
          autoplay: true,
          prevArrow: '.button-pre',
          nextArrow: '.button-nex',
          infinite: true,
          cssEase: 'linear',    

          customPaging: function (slider, i) {
            console.log(slider);
            return  (i + 1) + ' / ' + slider.slideCount;
        }
       
       });

       jQuery('.member-slider').slick({
        dots: true,
        customPaging: function(slider, i) {
 
          return '<span class="dot">'+jQuery(slider.$slides[i]).attr('title')+'</span>';
        },
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        prevArrow: '.button-prev',
        nextArrow: '.button-next',
        // autoplay: true,
        autoplaySpeed: 2000,
    });

    if (jQuery(window).width() > 960 ) {

      jQuery('.video-container .banner-video').height(jQuery(window).height() -32 );
      }

      if (jQuery(window).width() > 960 ) {

        jQuery('#banner').height(jQuery(window).height() - 32);

      }

        jQuery('.client-slider').slick({

          speed: 5000,
          autoplay: true,
          autoplaySpeed: 0,
          cssEase: 'linear',
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 1,
          arrows: false,
          buttons: false,
          
          responsive: [
          {
          breakpoint: 1024,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
          }
          },
          {
          breakpoint: 600,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          }
          },
          {
          breakpoint: 480,
          settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          }
          }
         
          ]
          });

          jQuery('.ads-mem-slider').slick({

            speed: 5000,
            autoplay: true,
            autoplaySpeed: 0,
            cssEase: 'linear',
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            initialSlide: 1,
            arrows: false,
            buttons: false,
            
            responsive: [
            {
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false,
            }
            },
            {
            breakpoint: 600,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            }
            }
           
            ]
            });

        jQuery(document).ready(function (e) {


          jQuery(".btn-expand").click(function (e) {
              
              var scrollpos = jQuery(window).scrollTop();

              var isRight= jQuery(this).hasClass('right');
              // var isRight1= jQuery(this).hasClass('right1');
              var isWrong= jQuery(this).hasClass('wrong');
              // var isWrong1= jQuery(this).hasClass('wrong1'); 


              // if ( jQuery('body.single-post,body.privacy-policy,body.legal-disclaimer,body.accessability,body.cookie-policy,body.search,body.modern-slavery-act,body.wobanner-head').length <= 0 ) {
              
              if(isRight)
              {
                  
                  jQuery(this).addClass('wrong').removeClass('right');
                  
                  jQuery('.brandicon').addClass('wrongicon').removeClass('righticon');

                  
              }

              if(isWrong)
              {
                  jQuery(this).removeClass('wrong').addClass('right');

                  jQuery('.brandicon').removeClass('wrongicon').addClass('righticon');
                  
              }

             
          // }

          
             jQuery('.menu-primary-container').toggle("slide", {
                 direction: "left"
                
             }, 600);
          });
      });


        var scroll = 0;

         if (jQuery(window).width() > 767) {

            jQuery( window ).scroll(function() {
            
                var scrollPos = jQuery(window).scrollTop();

                var width=jQuery( window ).width();
            
            if ( scrollPos > 50 && width > 767) {
                
                if ( scroll === 0 ) {

                    jQuery('header#custom-v-heads').addClass('header-saddow');

                    jQuery('header#custom-v-heads').animate({
                        
                       //  top: '-46px'

                    },300);

                    scroll++;
                    
                }
                
            }

          
            
            if ( scrollPos === 0 && width > 1200) {

                jQuery('header#custom-v-heads').removeClass('header-saddow');

                jQuery('header#custom-v-heads').animate({
                        
                        top: '0px'

                    },300);
            
                scroll = 0;
                
            }

         

        });

    }

        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
